import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-swierkowa"

const Investment = ({ location }) => {
  const title = "Świerkowa Residence"

  return (
    <Layout
      location={location}
      seo={{
        title: "Świerkowa Residence",
        image: withPrefix("/rsk-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Radom", url: "/en/flats/radom" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} />
      <Nav
        title={title}
        investment="Świerkowa Residence"
        investmentRaw="rezydencja_swierkowa"
      />
      <Apartments
        investment="Świerkowa Residence"
        investmentRaw="rezydencja_swierkowa"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <ContactForm
        formType="sale"
        investment="Świerkowa Residence"
        investmentRaw="rezydencja_swierkowa"
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export default Investment
